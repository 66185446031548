import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import {Table, Button, message, Modal} from 'antd';
import queryString from 'query-string';
import * as AppContext from '../../AppContext';
import * as AppActions from '../../AppActions';
import JStorageForm from './JStorageForm';
import URL_PATH from '../../UrlPath';
import {
  FORETASTE_TYPE_DISPLAY,
  FORETASTE_STATUS_DISPLAY,
  FORETASTE_STATUS,
  FORETASTE_TYPE,
} from '../../domain/Constants';
import {format} from '../../utils/date';

const PATH = URL_PATH.dashboardNews;
const PAGE_SIZE = 10;

function NewsPage(props) {
  const [records, setRecords] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    current: 1,
    total: 0,
    pageSize: PAGE_SIZE,
  });
  const [tableStateSnapshot, setTableStateSnapshot] = React.useState({});
  const [fetchConfigSnapshot, setFetchConfigSnapshot] = React.useState({
    paging: {
      offset: 0,
      limit: PAGE_SIZE,
    },
    query: {$or: [{label: '__NEWS__'}, {label: '__draft-NEWS__'}]},
    sorting: ['-created'],
  });
  const app = React.useContext(AppContext.Context);

  const tableColumns = [
    {
      title: '標題',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '狀態',
      dataIndex: 'label',
      key: 'label',
      render: (_, record, idx) => {
        return record.label.indexOf('__NEWS__') !== -1 ? '已發布' : '草稿';
      },
      filteredValue: tableStateSnapshot.filters?.label || null,
      filters: [
        {
          text: '已發布',
          value: '__NEWS__',
        },
        {
          text: '草稿',
          value: '__draft-NEWS__',
        },
      ],
    },
    {
      title: '成立時間',
      dataIndex: 'created',
      key: 'created',
      render: (_, record, idx) => {
        return format(record.created, 'YYYY-MM-DD HH:mm');
      },
      sortOrder:
        tableStateSnapshot.sorter?.columnKey === 'created' &&
        tableStateSnapshot.sorter?.order,
      sorter: (a, b) => a.created - b.created,
    },
    {
      title: '更新時間',
      dataIndex: 'updated',
      key: 'updated',
      render: (_, record, idx) => {
        return format(record.updated, 'YYYY-MM-DD HH:mm');
      },
      sortOrder:
        tableStateSnapshot.sorter?.columnKey === 'updated' &&
        tableStateSnapshot.sorter?.order,
      sorter: (a, b) => a.updated - b.updated,
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (_, record, idx) => {
        return (
          <Button
            onClick={async () => {
              await _toggleNewsState(record);
            }}>
            {record.label.indexOf('__NEWS__') !== -1 ? '下架' : '發布'}
          </Button>
        );
      },
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (_, record, idx) => {
        return (
          <Button
            onClick={async () => {
              let url = await app.actions.staffGetArticleEditorUrl(record.id);
              window.open(url, '_blank');
            }}>
            編輯
          </Button>
        );
      },
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (_, record, idx) => {
        return <Button onClick={() => _onDelete(record)}>刪除</Button>;
      },
    },
  ];

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      await _updateList();

      AppActions.setLoading(false);
    }

    if (app.staffUser) {
      fetchData();
    }
  }, [app.actions, app.staffUser]);

  const _toggleNewsState = React.useCallback(
    async (record) => {
      const hide = message.loading('更新文章狀態中...');
      try {
        let _updatedLabel =
          record.label.indexOf('__NEWS__') !== -1
            ? ['__draft-NEWS__']
            : ['__NEWS__'];
        await app.actions.staffUpdateNewsById(record.id, {
          ...record,
          label: _updatedLabel,
        });
        await _updateList();
        message.success('文章狀態已更新');
      } catch (err) {
        //
        message.error('無法更新文章狀態');
      }
      hide();
    },
    [app.actions],
  );

  const _updateList = React.useCallback(
    async (configs = null) => {
      setIsLoading(true);
      try {
        let _configs = configs || fetchConfigSnapshot;

        let _resp = await app.actions.staffGetNewsList(_configs);
        let _records = _resp.results;
        setPagination({
          total: _resp.total,
          pageSize: PAGE_SIZE,
          current: _configs.paging.offset / PAGE_SIZE + 1,
        });
        setRecords(_records);
      } catch (ex) {
        console.warn(ex);
      }
      setIsLoading(false);
    },
    [app.actions, fetchConfigSnapshot],
  );

  const _onDelete = React.useCallback(async (record) => {
    const _confirmDelete = async () => {
      AppActions.setLoading(true);
      let _result = false;
      try {
        let _resp = await app.actions.staffDeleteArticle(record.id);
        await _updateList();
        _result = true;
      } catch (err) {
        _result = false;
      }
      AppActions.setLoading(false);

      _result ? message.success('文章已刪除') : message.error('文章刪除失敗');
    };

    Modal.confirm({
      title: `是否確定刪除文章：${record.title}？`,
      content: '',
      cancelText: '取消',
      okText: '刪除',
      onOk: _confirmDelete,
    });
  }, []);

  const _onTableChange = React.useCallback(
    async (
      pagination, // {current: 1, pageSize: 3}
      filters,
      sorter,
      // currentDataSource: [], action: paginate | sort | filter
      extra,
    ) => {
      let _configs = {
        paging: {
          offset: 0,
          limit: PAGE_SIZE,
        },
        query: {$or: [{label: '__NEWS__'}, {label: '__draft-NEWS__'}]},
        sorting: ['-created'],
      };

      let _queries = [];
      for (let key of Object.keys(filters)) {
        if (filters[key] && filters[key].length > 0) {
          let _resultTypeQuery = {$or: filters[key].map((v) => ({[key]: v}))};
          _queries.push(_resultTypeQuery);
        }
      }
      _configs.query =
        _queries.length > 0
          ? {
              ..._configs.query,
              $and: _queries,
            }
          : _configs.query;

      if (extra.action === 'paginate') {
        _configs.paging.offset = (pagination.current - 1) * pagination.pageSize;
      }
      if (sorter && sorter.field && sorter.order) {
        _configs.sorting = [
          `${sorter.order === 'ascend' ? '' : '-'}${sorter.field}`,
        ];
      }
      AppActions.setLoading(true);
      await _updateList(_configs);
      setFetchConfigSnapshot(_configs);
      setTableStateSnapshot({filters, sorter});
      AppActions.setLoading(false);
    },
    [_updateList],
  );

  const _onGoCreate = React.useCallback(async () => {
    try {
      const hide = message.loading('創建文章中...', 0);
      let _article = await app.actions.staffCreateNews();
      hide();
      let url = await app.actions.staffGetArticleEditorUrl(_article.id);
      window.open(url, '_blank');
    } catch (err) {
      message.error('無法創建新文章');
    }
  }, [message, app.actions]);

  return (
    <Wrapper>
      <h2>最新消息列表</h2>
      <Button style={{marginBottom: 10}} onClick={_onGoCreate}>
        新增文章
      </Button>
      <Button
        style={{marginBottom: 10, marginLeft: 10}}
        onClick={() => _updateList()}>
        重新整理
      </Button>
      <Table
        locale={{
          triggerDesc: '排序遞減',
          triggerAsc: '排序遞增',
          cancelSort: '取消排序',
        }}
        loading={isLoading}
        dataSource={records}
        columns={tableColumns}
        rowKey={(record) => record.id}
        onChange={_onTableChange}
        pagination={{...pagination, showSizeChanger: false}}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;

  & > h2 {
    margin-bottom: 16px;
  }
`;

export default NewsPage;
